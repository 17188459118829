import React from 'react';
import styled from 'styled-components';
import { useSpring } from 'react-spring/hooks';
import { animated } from 'react-spring/hooks';
import { PRIMARY_BG_COLOR, PRIMARY_SUBTITLE_COLOR, TEXT_FONT, TITLE_FONT, SECONDARY_BG_COLOR } from '../../constants';
import { Col } from 'reactstrap';

export const TextCol = styled(Col)`
    font-family: ${TEXT_FONT};
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: 0.2px;
    color: ${props => (props.txtcolor ? props.txtcolor : PRIMARY_BG_COLOR)};

    p {
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 18px;
    }

    address {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 40px;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    ul.spaced {
        list-style: none;
        padding-left: 0;
        margin-top: 100px;
        margin-bottom: 100px;
        width: 50%;

        @media (max-width: 768px) {
            width: 100%;
        }

        li {
            font-family: ${TEXT_FONT};
            font-size: 22px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.2px;
            color: ${PRIMARY_BG_COLOR};
            border-bottom: 1px solid ${PRIMARY_BG_COLOR}60;
            padding-bottom: 50px;
            margin-bottom: 50px;
        }

        li:last-of-type {
            border-bottom: none;
        }

        li > a {
            color: ${PRIMARY_BG_COLOR};
            text-decoration: underline;
        }
    }

    li {
        font-size: 18px;
    }

    h1 {
        font-family: ${TITLE_FONT};
        font-size: 72px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.6;
        letter-spacing: -2.3px;
        margin-bottom: 30px;
    }

    h2 {
        font-family: ${TEXT_FONT};
        font-size: 44px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.15;
        letter-spacing: 0.3px;
        margin-bottom: 50px;
    }

    h3 {
        font-family: ${TEXT_FONT};
        letter-spacing: -1px;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;

        b {
            font-family: ${TITLE_FONT};
            letter-spacing: -1.7px;
        }
    }

    h4 {
        font-size: 18px;
        font-weight: bolder;
        font-family: ${TITLE_FONT};
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.1px;
        margin-bottom: 50px;
    }

    h3 > a {
        font-weight: bolder;
        color: ${props => (props.txtcolor ? props.txtcolor : PRIMARY_BG_COLOR)};

        &:hover {
            color: ${PRIMARY_SUBTITLE_COLOR};
            text-decoration: underline;
        }
    }

    a > h4 {
        font-family: ${TITLE_FONT};
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.2px;
        color: ${PRIMARY_BG_COLOR};
        margin-bottom: 0px;
    }

    a > p {
        font-family: ${TITLE_FONT};
        font-size: 22px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.2px;
        color: ${PRIMARY_BG_COLOR};
        margin-bottom: 10px;
    }

    a > p:last-of-type {
        margin-bottom: 40px;
    }

    a:hover {
        color: ${PRIMARY_SUBTITLE_COLOR};
    }

    .space {
        margin-bottom: 140px;
    }

    .title {
        margin-top: 0px;
        margin-bottom: 20px;
    }

    table {
        font-size: 14px;
        text-align: left;
    }

    div.definition {
        h4 {
            margin-top: 0px;
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 40px;
        }
    }

    ol > li {
        margin-bottom: 40px;
    }

    ol > li > ol {
        margin-top: 40px;
    }

    ul {
        margin-bottom: 50px;
        /* margin-top: -15px; */
    }

    @media (max-width: 992px) {
        font-size: 22px;

        h1 {
            font-size: 54px;
            margin-bottom: 30px;
        }

        h3 {
            font-size: 22px;
            letter-spacing: normal;
        }

        ul {
            margin-top: 20px;
        }

        li {
            font-size: 18px;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 38px;
        }

        p {
            line-height: 1.5;
        }
    }

    .btn {
        font-family: ${TITLE_FONT};
        font-weight: bold;
        padding: 5px 40px;
    }

    .btn-link {
       /*  background-color: ${SECONDARY_BG_COLOR};
        color: white;
        padding: 5px 10px;
        */

       background-color: ${SECONDARY_BG_COLOR};
        color: white;
        padding: 5px 10px;

        :hover {
            color: white;
            text-decoration: none;
        }
    }
`;

export const PageWrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: background-color linear 200ms;

    background-color: ${props => props.bg};
`;

const AnimatedPage = styled(animated.div)`
    width: 100%;
    height: 100%;
    background-size: contain;
    position: absolute;
    transform: translateY(-100%);
    background: white;
    background-position: center;
`;

const BlankAnimatedPage = styled(AnimatedPage)`
    background: none;
`;

export const Page = ({ activePage, page, background, children }) => {
    const pageDiff = Math.abs(page - activePage) / (page - activePage);
    const [style] = useSpring({
        transform: !pageDiff ? 'translateY(0%)' : `translateY(${pageDiff * 100}%)`,
        transformInternal: `translateY(0)`,
        config: {
            duration: 2000,
        },
    });
    return (
        <AnimatedPage
            style={{
                transform: style.transform,
                backgroundImage: `url(${background})`,
                zIndex: 6 - page,
            }}
        >
            <BlankAnimatedPage>{children}</BlankAnimatedPage>
        </AnimatedPage>
    );
};
